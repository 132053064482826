<template>
    <v-container fluid pa-0>
        <v-row row wrap no-gutters>
            <template  v-if="cartNotEmpty">
                <template v-if="showTitle">
                    <v-col cols="12" class="text-center">
                        <h1>{{ title }}</h1>
                    </v-col>
                </template>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="items"
                        mobile-breakpoint=350
                        hide-default-footer
                    >
                        <template v-slot:item="{ item, index }">
                            <tr>
                                <td>{{ item.name }} - {{ item.variation_name }}</td>
                                <td class="text-center">${{ (item.base_price_money.amount/100).toFixed(2) }}</td>
                                <td class="text-center">{{ item.quantity }}</td>
                            </tr>

                            <template v-if="index==items.length-1">
                                <tr v-for="option in options" v-bind:key="option.name">
                                    <td>{{option.name + ": " + option.value}}</td>
                                </tr>
                            </template>
                        </template>
                    </v-data-table>
                    <v-row no-gutters>
                        <v-col class="pt-2 pl-5" >
                            <p class="text-right mr-5" :style="{'font-size': '14px'}">
                                Sales Tax: ${{tax.toFixed(2)}}<br>
                                <strong>Total: ${{total.toFixed(2)}} </strong>
                            </p>
                        </v-col>
                    </v-row>
                </v-col>
            </template>
            <template v-else>
                 <v-container fluid>
                    <v-row row wrap>
                        <v-col cols="12" class="text-center" mt-4>
                            <h1>Your Cart is Empty</h1>
                        </v-col>
                    </v-row>
                 </v-container>
            </template>
        </v-row>
    </v-container>
</template>


<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        cart: Object,
        showTitle: Boolean,
        showCheckoutButton: Boolean,
        showDetails: Boolean
    },
    mounted(){
    },
    computed: {
        ...mapGetters([
            'orderCode',
            'orderComplete',
       //     'cart',
        ]),
        headers () {
            let h = [];
            h.push({ text: 'Item', align: 'left', sortable:false, value: 'name'})
       //     if(!this.$vuetify.breakpoint.xs)
                h.push({ text: 'Price', value: 'price', sortable: false, align: 'center'})
            h.push({ text: 'Qty', value: 'selected', sortable: false, align: 'center'})
       //     if(!this.$vuetify.breakpoint.xs)
        //        h.push({ text: 'Line Total', value: 'status', align: 'right'})
            return h
        },
        cartNotEmpty () {
            return this.cart != null && this.cart != undefined
        },
        items() { return this.cart.line_items},
        options() { 
            return this.cart.options
        },
        tax () { return this.cart.total_tax_money.amount/100},
        total () { return this.cart.total_money.amount/100},
    },
}
</script>