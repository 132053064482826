<template>
    <v-container fluid>
        <v-row row wrap dense>
            <v-col cols="12" class="mt-2 pa-0">
                <ul style="list-style-type:none" class="mb-2" :style="{'font-size': '14px'}">
                    <li><strong>Order Date</strong>: {{ ordered }}</li>
                    <!--<li><strong>Payment</strong>: {{ paymentDescription}}</li>-->
                    <li v-if="start"><strong>Pickup Begins</strong>: {{ start }}</li> 
                    <li v-if="deadline"><strong>Pickup Ends</strong>: {{ deadline }}</li> 
                    <li v-if="pickup"><strong>Picked Up</strong>: {{ pickup }}</li> 
                    <li v-if="delivery"><strong>Sent for Delivery</strong>: {{ delivery }}</li> 
                    <li v-if="fulfillment"><strong>Pickup Location</strong>: {{ fulfillment }}</li> 
                    <li v-if="refund"><strong>Refunded</strong>: {{ refund }}</li> 
                </ul>
            </v-col>

            <v-col v-if="allowChangeFulfillment" cols="12" class="ml-3 mb-3 mt-n1 pa-0">
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-on="on" v-bind="attrs" text small color="primary" class="pa-1">
                            <v-icon small>mdi-pencil</v-icon>
                            <span class="text-caption">Select Pickup Location</span>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>Select Pickup Location</v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" v-if="product.fulfillments">
                                        <v-radio-group v-model="newFulfillment">
                                        <v-radio
                                            v-for="(f, n) in product.fulfillments"
                                            :key="(n-1)"
                                            :label="titleCase(f.type)+' @ '+f.location"
                                            :value="f"
                                        ></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="menu = false">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="confirmFulfillment">OK</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </v-col>

            <v-col cols="12" class="text-center text-overline ma-0 pa-0">
                <template v-if="showBarcode">
                    <v-card flat >
                        Show barcode to pickup order
                        <qriously v-bind:value="order.code" :size="130" />
                    </v-card>
                </template>
            </v-col>
            <v-col center cols="12" class="ma-0 pa-0">
                <SquareCart
                    v-bind:cart="order"
                    v-bind:showTitle="false"
                    v-bind:showDetails="true"
                />
                <div class="mb-2 text-center" :style="{'font-size': '14px'}">
                    {{ paymentDescription}}
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SquareCart from './SquareCart'
import moment from 'moment'
import { mapActions } from 'vuex'
export default {
    data () {
        return {
            menu: false,
            checkbox: false,
            newFulfillment: null,
        }
    },
    props: {
        order: Object,
        showCode: Boolean
    },
    components: {
        SquareCart
    },
    watch: {
        newFulfillment (newVal) {
            console.log('newFulfillment', newVal)
        }
    },
    computed: {
        ordered () {
            return this.order.dateOrdered //moment(this.order.dateOrdered, "YYYY-MM-DD HH:mm").calendar()
        },
        pickup () {
            if(this.order.pickedUp != undefined && this.order.pickedUp != "") {
                return this.order.pickedUp  + " by " + this.order.pickedUpBy
            }
            else return false
        },
        delivery () {
            if(this.order.deliveryInitiated != undefined && this.order.deliveryInitiated != "") {
                return this.order.deliveryInitiated  + " to " + this.order.deliveredTo
            }
            else return false
        },
        fulfillment () {
            if(this.order.fulfillment != undefined && this.order.fulfillment != "") {
                if(this.order.fulfillment.type){
                    return this.order.fulfillment.location
                }
            }
            else return false
        },
        allowChangeFulfillment(){
      //      console.log(this.order.fulfillment, this.product)
            return this.product && !this.order.fulfillment
        },
        refund () {
            if(this.order.refund != undefined) { 
                let date = moment(this.order.refund.created_at, "YYYY-MM-DDTHH:mm:ssZ").format("M/D/YYYY ha")
                return "$" + this.order.payment.amount/100 + " " + date
            }
            else return false
        },
        showBarcode () {
            return this.showCode && !this.pickup && !this.delivery && !this.refund
        },
        start () {
            if(this.order.product == undefined || this.order.product == null)  //receipt is reused by admin/EditOrder which does not have Products attached
                return false
            let now = moment()
            let start = moment(this.order.product.pickupStart, "YYYY-MM-DD HH:mm")
            if(!this.pickup && !this.delivery){
                return start.format("M/D/YYYY ha")
            }
            else return false
        },
        deadline () {
            if(this.order.product == undefined || this.order.product == null)  //receipt is reused by admin/EditOrder which does not have Products attached
                return false
            let now = moment()
            let end = moment(this.order.product.pickupEnd, "YYYY-MM-DD HH:mm")
            if(!this.pickup && !this.delivery){
                return end.format("M/D/YYYY ha")
            }
            else return false
        },
        paymentDescription () {
            if(this.order.payment.missingRecord || this.order.payment.card_brand == undefined)
                return "Record missing"
            else
                return "Paid $" + (this.order.payment.amount/100).toFixed(2) + " " + this.order.payment.card_brand + " xx" + this.order.payment.last_4
        },
        orderOptions () {
         //   console.log(this.order.options)
            return this.order.options
        },
        product () {
            let products = this.$store.state.products
          //  console.log(products)
            if(products){
                const product = products.find(p => p.productId == this.order.productId)
                if(product)
                    return product
            }
            return null
        },
    },
    methods: {
        ...mapActions(['updateOrderFulfillment']),
        titleCase(str){
            return str.toLowerCase().split(' ').map(function(word) {
                return word.replace(word[0], word[0].toUpperCase());
            }).join(' ');
        },
        confirmFulfillment(){
            if(this.order && this.newFulfillment)
                this.updateOrderFulfillment({order: this.order, newFulfillment: this.newFulfillment})
            this.menu = false
        },
    }
}
</script>