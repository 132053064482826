<template>
    <v-container fluid>
                <v-row no-gutters>
                    <v-col cols="2" class="pl-5">
                        <v-btn @click="sortName" icon :color="nameColor">
                            <v-icon medium>{{nameIcon}}</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="8"></v-col>
                    <v-col cols="2" class="mr-0">
                        <v-btn @click="sortDate" icon :color="dateColor">
                            <v-icon medium>{{dateIcon}}</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
        <v-row no-gutters row wrap>
            <v-col cols="12">
                <v-card v-if="ordersWithStatus.length>0" flat>
                <v-list rounded >
                    <v-list-group
                        v-for="item in ordersWithStatus"
                        :key="item.code"
                        v-model="item.active"
                        no-action
                    >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-row no-gutters align="center" class="pa-3">
                                <v-col justify cols="7">
                                    <div class="subtitle-1 font-weight-medium">{{ item.name }}</div>
                                    <div class="body-2 font-weight-light">{{ "(" + item.line_items[0].quantity + ") " + item.line_items[0].variation_name}}</div>
                                </v-col>
                                <v-col align="right" cols="5">
                                    <div class="body-2 font-weight-light">{{status + " " + getDate(item)}}</div>
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                    </template>
                    <v-list-item class="pl-0"  :style="{border: '1px solid #4c8bf5'}">
                        <v-row dense>
                        <v-col cols="12" class="ma-0 pa-0">
                            <SquareReceipt :item=item item-key="name" ref="receipt"
                                v-bind:order=item
                                v-bind:showTitle="true"
                                v-bind:showDetails="true"
                                :showCode=true
                            />
                        </v-col>
                        </v-row>
                    </v-list-item>
                    </v-list-group>
                </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SquareReceipt from '@/components/payment/SquareReceipt'
import moment from 'moment'
export default {
    components: {
        SquareReceipt
    },
    data () {
        return {
            selected: '',
            sortType: "date",
            sortAscending: false,
            nameColor: "grey lighten-1",
            dateColor: "primary",
            nameIcon: "mdi-sort-variant-remove",
            dateIcon: "mdi-sort-descending"
        }
    },
    props: {
        status: String,
        orders: Array
    },
    computed: {
        ordersWithStatus () {
            let now = moment()
            this.orders.forEach(order => {
                if(order.status == "Completed"){
                    order.displayStatus = order.line_items[0].quantity + "x " + order.line_items[0].variation_name
                }
                else{
                    if(order.status == "Refunded"){
                        order.displayStatus = "Refunded"
                    }
                    else{
                        try{
                        let start = moment(order.product.pickupStart, "YYYY-MM-DD HH:mm")
                        let end = moment(order.product.pickupEnd, "YYYY-MM-DD HH:mm")
                        if(start.diff(now) < 0){ //after started
                            if(end.diff(now) < 0){
                                order.displayStatus = "Pickup overdue"
                            }
                            else{
                                order.displayStatus = "Available for pickup until " + end.calendar()
                            }
                        }
                        else{
                            order.displayStatus = "Ordered. Pickup begins " + start.calendar()
                        }
                        }catch(err) {
                            order.displayStatus = "Error"
                        }
                    }
                }
            });
            this.orders.sort((a, b) => {
                var response = 0
                if(this.sortType == "name"){
                    response = (a.name > b.name) ? 1 : -1
                }
                else{
                    response = (this.getSortableDate(a) > this.getSortableDate(b)) ? 1 : -1
                }
                if(!this.sortAscending)
                    response = response*-1
                return response
            })
            return this.orders
        },
    },
    methods: {
        sortName (){
            this.sort("name")
            this.nameColor = "primary"
            this.dateColor = "grey lighten-1"
            this.nameIcon = (this.sortAscending? "mdi-sort-ascending": "mdi-sort-descending")
            this.dateIcon = "mdi-sort-variant-remove"
        },
        sortDate () {
            this.sort("date")
            this.dateColor = "primary"
            this.nameColor = "grey lighten-1"
            this.dateIcon = (this.sortAscending? "mdi-sort-ascending": "mdi-sort-descending")
            this.nameIcon = "mdi-sort-variant-remove"
        },
        sort (type) {
            if(this.sortType != type)
                this.sortType = type
            else{
                this.sortAscending = !this.sortAscending
            }
            this.nameColor = ( this.sortType == "name" ? "primary": "grey lighten-1")
            this.dateColor = ( this.sortType == "date" ? "primary": "grey lighten-1")
            this.icon = (this.sortAscending? "mdi-sort-ascending": "mdi-sort-descending")
        },
        getDate (order) {
            if(this.status == "Refunded"){
                return moment(order.refund.created_at, "YYYY-MM-DDTHH:mm:ssZ").calendar()
            }
            if(this.status == "Completed"){
                if(order.deliveryInitiated != undefined)
                    return moment(order.deliveryInitiated, "YYYY-MM-DD HH:mm").calendar()
                else
                    return moment(order.pickedUp, "YYYY-MM-DD HH:mm").calendar()
            }

            return moment(order.dateOrdered, "YYYY-MM-DD HH:mm").calendar()
        },
        getSortableDate (order) {
            if(this.status == "Refunded"){
                return order.refund.created_at
            }
            if(this.status == "Completed"){
                if(order.deliveryInitiated != undefined)
                    return order.deliveryInitiated
                else
                    return order.pickedUp
            }
            return order.dateOrdered
        }
    }
}
</script>
<style scoped>
::v-deep .v-list-item {
  padding: 0;
}
</style>